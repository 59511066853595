import baseConfig from '@/settings/config/base.json';
import devConfig from '@/settings/config/development/index.json';
import qaConfig from '@/settings/config/qa/index.json';
import uatConfig from '@/settings/config/uat/index.json';
import smokeConfig from '@/settings/config/smoke/index.json';
import prodConfig from '@/settings/config/production/index.json';
import merge from 'deepmerge';

// get environment
let env = 'development';
if (process.env.VUE_APP_ENV_OVERRIDE) {
  env = process.env.VUE_APP_ENV_OVERRIDE;
} else if (process.env.NODE_ENV === 'production') {
  // will be replaced by nginx
  env = (window as any).coverhubEnv;
}

let envSpecificConfig: any = {};
switch (env) {
  case 'development':
    envSpecificConfig = devConfig;
    break;
  case 'qa':
    envSpecificConfig = qaConfig;
    break;
  case 'uat':
    envSpecificConfig = uatConfig;
    break;
  case 'smoke':
    envSpecificConfig = smokeConfig;
    break;
  case 'production':
    envSpecificConfig = prodConfig;
    break;
  default:
    throw Error('Unknown env: ' + env);
}
const envConfig: any = merge(baseConfig, envSpecificConfig);
envConfig.environment = env;

export function isSentryEnabled() {
  // TODO potentially replace with azure app insights.
  return false;
  // return (['qa', 'uat', 'production'].includes(envConfig.environment || '') ||
  //   window.navigator.userAgent.includes('sentry-enabled')) || process.env.VUE_APP_FORCE_SENTRY === 'true';
}

export default envConfig;
