

import Util from '@/utils/Util';
import {Component, Vue, Prop, Watch, Inject} from 'vue-property-decorator';
import {Validator} from 'vee-validate';
import _ from 'lodash';
import {State} from 'vuex-class';
import {IdleService} from '@/services/IdleService';
import EventBus from '@/common/EventBus';
import stringHash from 'string-hash';

const SCREEEN_DATE_FORMAT = 'MM/DD/YYYY';

@Component({
  name: 'FactMixin',
  $_veeValidate: {
    validator: 'new'
  }
})
export default class FactMixin extends Vue {
  public modelValue: any = null;
  public FACT_CHANGE_DEBOUNCE_TIME_OUT: number = 500;
  @Inject() public readonly $validator!: Validator;

  protected isFocused = false;
  @State protected cms: any;
  @State protected app: any;
  @Prop() protected productId!: string;
  @Prop() protected componentCode!: string;
  @Prop() protected factData: any;
  @Prop() protected isBasic!: boolean;
  @Prop() protected hasTextLayout!: boolean;
  @Prop() protected validators!: string;
  @Prop({default: false}) protected singleFact!: boolean;
  @Prop({default: false}) protected autofill!: boolean;
  @Prop() protected autofillText?: string;
  @Prop() protected updateFn: any;
  @Prop({default: false}) protected separateHeader!: boolean;
  @Prop() private descriptionName!: string;
  @Prop() private preloadedValue: any;
  @Prop() private disabled!: boolean;
  @Prop() private showTooltip!: boolean;
  @Prop() private tabindex?: number | string;
  @Prop() private group?: string;
  @Prop({default: false}) private visible!: boolean;

  private currentValue: any = null;

  get description() {
    return Util.stringToHtml(this.descriptionName);
  }

  get isDisplayLabel() {
    return this.factData?.tags?.includes('displayLabel');
  }

  public updateModelAndValidate(modelValue, forced: boolean = false, autofillFlag: boolean = false) {
    IdleService.resetTimer();
    if (modelValue !== this.currentValue || forced) {
      if (typeof this.updateFn === 'function') {
        let factValue = modelValue;
        if (modelValue !== undefined && this.modelValue !== null &&  typeof modelValue !== 'object') {
          factValue = modelValue.toString();
          if (typeof modelValue === 'boolean') {
            factValue = factValue.toUpperCase();
          }
        }
        if (!this.isBasic) {
          if (this.autofill && autofillFlag) {
            this.updateFn(this.factData, factValue, true);
          } else {
            this.updateFn(this.factData, factValue);
          }
        } else {
          this.$emit(factValue, this.factData);
        }
      }
      this.validate();
      this.currentValue = modelValue;
    }
  }

  protected onFocusHandler() {
    this.isFocused = true;
  }

  protected onBlurHandler() {
    this.isFocused = false;
  }

  protected async validate(scope?: string) {
    await (this as any).$resetFactErrors(
      this.isBasic ? this.factData.label : this.getUID(),
      this.isBasic ? true : ['PHONE_NUMBER', 'NUMBER', 'EMAIL'].includes(this.factData.type)
    );
    await this.$validator.validateAll(scope || 'scope.*');
  }

  protected isDisabled() {
    return !!this.disabled || _.get(this.factData, 'readonly');
  }

  protected getUID() {
    // return Util.generateUid(this.productId, this.factData.id);
    if (this.componentCode) {
      return Util.generateUid(this.componentCode, this.componentCode);
    }
    return this.factData._UUID;
  }

  protected hasDescription(value: string) {
    return this.showTooltip && !_.isEmpty(value);
  }

  protected getDateFormat() {
    if (_.get(this.cms, 'theme.dateFormat')) {
      return this.cms.theme.dateFormat;
    }
    return SCREEEN_DATE_FORMAT;
  }

  private created(this: any) {
    this.onDefaultChange.bind(this);
    this.onVisibleChange.bind(this);
    this.onVisibleChange();
    this.updateModelAndValidate = _.debounce(this.updateModelAndValidate, Util.RE_RENDER_DEBOUNCE, Util.RE_RENDER_DEBOUNCE_OPTIONS);
  }

  @Watch('visible')
  private onVisibleChange(val: any = null, oldVal: any = null) {
    this.onDefaultChange();
    this.modelValue = this.currentValue;
  }

  @Watch('preloadedValue')
  private onDefaultChange(val: any = null, oldVal: any = null) {
    this.currentValue = !_.isEmpty(this.preloadedValue) ? this.preloadedValue : this.factData.defaultValue;
    if (!this.isFocused) {
      this.modelValue = this.currentValue;
    }
    this.validate(this.getUID());
  }

  get isSmallScreen() {
    return this.$vuetify.breakpoint.width < 430;
  }

  private beforeDestroy() {
    this.beforeRemove();
  }

  private beforeRemove() {
    if (this.factData.id) {
      EventBus.$emit('factRemoved', 'id-' + stringHash(this.factData.id), this.productId, this.factData.id);
    }
  }

  private onTab(e) {
    e.preventDefault();
    const facts = [].slice.call(document.querySelectorAll('[aria-expanded="true"] input[tabindex="0"]:not([disabled="disabled"])'));
    if (facts) {
      try {
        const found = _.findIndex(facts, e.currentTarget);
        if (found >= 0) {
          e.currentTarget.blur();
          (facts[found + 1] as HTMLInputElement).focus();
        }
      } catch (err) {
        e.currentTarget.blur();
        (facts[0] as HTMLInputElement).focus();
      }
    }
  }
}
