





































import {Component, Vue, Prop, Watch} from 'vue-property-decorator';
import _ from 'lodash';

@Component({
	name: 'Odometer',
})
export default class Odometer extends Vue {
  @Prop() private value!: boolean;
  @Prop() private size!: number;
  @Prop() private units!: string;

  private indexes: any[] = [];
  private wheelsWidth: any[] = [];
  private animating = false;
  private wrapperWidth = '0';
  private animation = false;

  private wheel = ['\u200C‌‌', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '$', '.', ','];

  @Watch('value')
  private onValue(val, oldVal, animation = true) {
    this.wrapperWidth = 'auto';
    const tmp: any[] = [];
    val.split('').forEach((ch, i) => {
      const index = this.wheel.indexOf(ch);
      tmp[i] = index >= 0 ? index : 0;
    });
    this.animating = animation;
    setTimeout(() => {
      this.indexes = tmp;
      this.wrapperWidth = _.get(this.$refs.wrapper, 'offsetWidth', 0) + 'px';
      const wheelsWidth: any[] = [];
      if (_.get(this.$refs, 'wrapper.children')) {
        _.forEach((this.$refs.wrapper as HTMLElement).children, (el) => {
          wheelsWidth.push(getComputedStyle(el).width);
        });
      }
      this.wheelsWidth = wheelsWidth;
      setTimeout(() => {
        this.animating = false;
        this.animation = true;
        this.wrapperWidth = _.get(this.$refs.wrapper, 'offsetWidth', 0) + 'px!important';
      }, 1200);
    }, 0);
  }

  private mounted() {
    this.onValue(this.value, null, false);
  }

  private activated() {
    this.onValue(this.value, null, false);
  }

  private getTransform(index) {
    return 'translateY(-' + this.size * index + this.units + ')';
  }
}
