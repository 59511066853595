















import {Component, Vue, Prop} from 'vue-property-decorator';
import _ from 'lodash';
import {State} from 'vuex-class';

@Component({
	name: 'CustomButton',
})
export default class CustomButton extends Vue {
  @Prop() private type!: string;
  @Prop() private buttonColor!: string;
  @Prop() private borderColor!: string;
  @Prop() private fontColor!: string;
  @Prop() private rounded!: boolean;
  @Prop() private loading!: boolean;
  @State private cms: any;

  private inProgress = false;

  private borderForColors = ['secondary'];

  get background() {
    return this.buttonColor || _.get(this.$colors, this.type + 'Button', '#e7e7e7');
  }

  get border() {
    return this.borderColor || (this.borderForColors.includes(this.type) ? 'solid 2px ' + this.$colors.border : 'solid 2px ' + this.background);
  }

  get textColor() {
    return this.fontColor || _.get(this.$colors, this.type + 'ButtonFont', '#000');
  }

  private handleClick(e: any) {
    this.inProgress = true;
    this.$emit('click', e);
    setTimeout(() => this.inProgress = false, 250);
  }

}
