













import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import AppBar from '@/components/layouts/AppBar.vue';
import CookieBar from '@/components/layouts/CookieBar.vue';
import Util from '@/utils/Util';
import EventBus from '@/common/EventBus';
import globalDialog from '@/components/common/Dialog.vue';
import { CMSContentService } from '@/services/CMSService';
import { defaultLocale } from '@/plugins/intl';
import _ from 'lodash';
import axios from 'axios';

@Component({
  name: 'AuthContainer',
  components: {
    AppBar,
    CookieBar,
    globalDialog
  },
})
export default class AuthContainer extends Vue {
  @State private app: any;
  @State private cms: any;
  @Action('app/setError') private setError: any;
  @Action('app/setLanguage') private setLanguage: any;
  @Action('app/setDetectedCountry') private setDetectedCountry: any;
  @Action('app/setValue') private setValue: any;

  private themeReady: boolean = false;
  private isError: boolean = false;
  private isShowHeader: boolean = true;

  @Watch('themeReady')
  private removeLoader(newVal, oldVal) {
    if (oldVal === false && newVal === true) {
      Util.removeLoader();
    }
  }

  private async created() {
    window.addEventListener('scroll', this.onScroll);
    EventBus.$on('reload-cms', async (locale) => {
      this.reload(locale);
    });
    this.isError = _.get(this.$route, 'query.error', false);
    await this.load();
  }

  private beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll);
  }

  private onError(msg: string) {
    this.setError(msg);
    this.themeReady = true;
    this.$router.push({ path: '/error' });
  }

  private getLocation() {
    const setDefaultCountry = () => {
      this.setDetectedCountry(_.get(this.app, 'config.countryDefault.country[0]', '').toUpperCase());
    };
    if (_.get(this.app, 'config.countryDefault.defaulted', false)) {
      setDefaultCountry();
    } else {
      axios.get('https://geoip-db.com/json/').then(({data}) => {
        this.setDetectedCountry(_.get(data, 'country_code', ''));
      }).catch(() => {
        setDefaultCountry();
      });
    }
  }

  private onScroll() {
    const offset: number = 100;
    if (this.$vuetify.breakpoint.xsOnly && (document.body.scrollTop > offset || document.documentElement.scrollTop > offset)) {
      this.isShowHeader = false;
    } else {
      this.isShowHeader = true;
    }
  }

  private async load() {
    EventBus.$emit('init-app', async () => {
      this.getLocation();
      EventBus.$emit('onReload');
      this.themeReady = true;
      await this.loadTranslations();
      Util.initLivechat(this.app.config.livechat);
    });
  }

  private async reload(locale) {
    this.$i18n.locale = locale;
    const query: any = Object.assign({}, this.$route.query, { language: this.app.language });
    this.$router.push({ query });
    EventBus.$emit('init-app', async () => {
      EventBus.$emit('onReload');
    });
  }

  private async loadTranslations() {
    const translations = await CMSContentService.getTranslations(this.app.targetSegment, this.app.language);
    const i18n = this.$i18n || this.$root.$i18n;
    for (const translation of translations) {
      i18n.mergeLocaleMessage(translation.locale, translation.translation);
    }
    i18n.locale = _.get(this.$router.currentRoute, 'query.language', this.app.language);
    this.setLanguage(i18n.locale);
    this.$emit('onLanguageChange', i18n.locale);
  }
}
