import EventBus from '@/common/EventBus';
import _ from 'lodash';

const requestCount = {};
const requestMap = {};

const DEFAULT_TIMEOUT = 100;

export default () => {
  EventBus.$on('api-request', (data) => {
    increaseRequestCount(data.method);
    logRequest(data);
    openDialog(data.method, data.url);
  });

  EventBus.$on('api-done', (data) => {
    decreaseRequestCount(data.method);
    deleteRequest(data);
    closeDialog(data.method);
  });

  EventBus.$on('api-error', (err) => {
    decreaseRequestCount(err.method);
    deleteRequest(err);
    closeDialog(err.method);
  });

  EventBus.$on('stepper-busy', () => {
    increaseRequestCount('stepper');
    EventBus.$emit(`open-loading-dialog`);
  });

  EventBus.$on('stepper-idle', () => {
    decreaseRequestCount('stepper');
    closeDialog('stepper');
  });

  EventBus.$emit(`open-loading-dialog`);
};

function increaseRequestCount(method) {
  requestCount[method] = Math.min((requestCount[method] ||  0) + 1, 1);
}

function logRequest(data) {
  if (data && data.method && data.url) {
    requestMap[data.method] = requestMap[data.method] || {};
    requestMap[data.method][data.url] = true;
  }
}

function openDialog(method, url) {
  if (requestCount[method] === 1) {
    if (method === 'get') {
      _.debounce(() => {
        if (requestMap[method][url]) {
          EventBus.$emit(`open-loading-dialog`);
        }
      }, DEFAULT_TIMEOUT)();
    } else {
      EventBus.$emit(`open-loading-dialog`);
    }
  }
}

function decreaseRequestCount(method) {
  requestCount[method]--;
}

function deleteRequest(data) {
  if (data && data.method && data.url) {
    requestMap[data.method][data.url] = false;
  }
}

function closeDialog(method) {
  requestCount[method] = Math.max(requestCount[method], 0);
  if (requestCount[method] === 0) {
    EventBus.$emit(`close-loading-dialog`);
  }
}
