


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  name: 'FactTooltip',
})
export default class FactTooltip extends Vue {
  @Prop() private description?: string;
  @Prop() private direction?: string;
  @Prop() private icon?: string;
  @Prop() private showOnLoad?: boolean;
  @Prop() private styleFact?: object;

  private show = false;
  private initial = true;

  private mounted() {
    setTimeout(() => {
      this.show = !!this.showOnLoad;
    }, 1000);
  }

  private close() {
    this.show = false;
    this.initial = false;
  }
}
