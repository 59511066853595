import Vue from 'vue';
import _ from 'lodash';
import store from '@/store';

export const formatCurrency = ( amount: any, symbol: string = _.get(store.state.app.config, 'currency', '$')) => {
  const prefix = symbol ? symbol + (symbol.length > 1 ? ' ' : '') : '';
  if (_.isNumber(amount)) {
    return prefix + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }
  return amount;
};

Vue.filter('capitalize', (value: string) => {
  if (!value) {
    return '';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('currency', formatCurrency);

Vue.filter('percentage', ( value: any, symbol: string = '', numberOnly: boolean = false ) => {
  const postfix = symbol ? symbol : '';
  if (!_.isNaN(_.toNumber(value)) && !numberOnly) {
    return value + postfix;
  } else if (numberOnly) {
    return value.replace('%', '');
  }
  return value;
});

const formatMarkdown = (value: any, colors: any) => {
  const matches = value.match(/\[.+\]\(.+\)/g);
  let html = value;
  _.forEach(matches, (match) => {
    if (!match) {
      return;
    }
    const temp1 = match.substring(1, match.lastIndexOf('(') - 1);
    const temp2 = match.substring(match.lastIndexOf(']') + 2, match.length - 1);
    if (temp1 && temp2) {
      const val = formatMarkdown(temp1, colors);
      const tags = temp2.split(';');
      switch (tags[0]) {
        case 'BOLD':
          html = html.replace(match, `<b>${val}</b>`);
          break;
        case 'LIST_ITEM':
          html = html.replace(match, `<li>${val}</li>`);
          break;
        case 'HYPERLINK':
          html = html.replace(match, `<a style="color:${colors.secondary}" href="${tags[1]}" target="_blank">${val}</a>`);
          break;
      }
    }
  });
  return html;
};

Vue.filter('markdown', (value: any, colors: any) => {
  return formatMarkdown(value, colors);
});
