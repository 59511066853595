





























import {Component, Vue, Watch} from 'vue-property-decorator';
import {CMSContentService} from '@/services/CMSService';
import _ from 'lodash';
import VueMarkdown from 'vue-markdown';
import {State} from 'vuex-class';

@Component({
  name: 'CookieBar',
  components: {
    VueMarkdown
  },
})
export default class CookieBar extends Vue {
  @State private app: any;
  @State private cms: any;

  private COOKIE_DISCLAIMER: string = '';
  private visible: boolean = false;

  @Watch('app.language')
  private onLanguageChanged() {
    this.loadDisclaimer();
  }

  private created() {
    this.loadDisclaimer();
  }

  private loadDisclaimer() {
    this.visible = this.cookieBarEnabled && !this.$cookies.isKey('cookies-acknowledged');
    if (this.visible) {
      CMSContentService.getStaticText('COOKIE-DISCLAIMER', this.app.language).then((response) => {
        this.COOKIE_DISCLAIMER = _.get(response, 'fields.content');
      });
    }
  }

  private acceptCookies() {
    this.$cookies.set('cookies-acknowledged', 'true', new Date(2038, 1, 19));
    this.visible = false;
  }

  get cookieBarEnabled() {
    return _.get(this.app.config, 'cookieBarEnabled', true);
  }
}
