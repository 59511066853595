














import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import { State } from 'vuex-class';
import ContactInformation from '@/components/layouts/ContactDialog/ContactInformation.vue';
import ContactForm from '@/components/layouts/ContactDialog/ContactForm.vue';

@Component({
  name: 'ContactDialog',
  components: {
    ContactInformation,
    ContactForm
  }
})

export default class ContactDialog extends Vue {
  @Prop({default: false}) private value!: boolean;
  @State private app: any;
  @State private cms: any;

  @Watch('value')
  private onVisible(opening: boolean) {
    if (opening && this.$refs.contactForm) {
      (this.$refs.contactForm as ContactForm).populateFields();
    }
  }

  private close() {
    this.$emit('input', false);
    (this.$refs.contactForm as ContactForm).clearFields();
  }


}
