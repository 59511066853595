










































import { Component, Vue } from 'vue-property-decorator';
import Util from '@/utils/Util';
import {State} from 'vuex-class';
import EventBus from '@/common/EventBus';
import _ from 'lodash';

@Component({
	name: 'ProductCardDetails',
})
export default class ProductCardDetails extends Vue {
  @State private cms: any;
  @State private app: any;

	private showDrawer: boolean = false;
	private content: any = null;

  get secondaryBorder() {
    return '2px solid ' + this.$colors.secondaryButtonBorder;
  }

	public toggleDrawer(show: any = null) {
		if (show !== null) {
			this.showDrawer = show;
		} else {
			this.showDrawer = !this.showDrawer;
		}
  }

	private created() {
	  EventBus.$on('open-product-detail', (content) => {
	    this.content = content;
	    this.showDrawer = true;
    });
  }

  private processMarkdown(text) {
    if (_.isEmpty(text)) {
      return;
    }
    return text.replace(/\n/g, '<br/><span class=\'break\'/>');
  }

  private onDownloadClick(url) {
	  this.gaLogCatalogueEvent(url);
	  window.open(url, '_blank');
  }

  private gaLogCatalogueEvent(value) {
    Util.gaLogCatalogueEvent(this, 'Download', 'Product sheet', value);
  }
}
