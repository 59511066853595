






























































































import {Component, Vue} from 'vue-property-decorator';
import { State, Action, Getter } from 'vuex-class';
import Util from '@/utils/Util';
import FactPhoneNumber from '@/components/common/form/fact/FactPhoneNumber.vue';

import {SalesforceService} from '@/services/SalesforceService';
import _ from 'lodash';
import App from '@/models/app';
import CMS from '@/models/cms';
import Proposal from '@/models/proposal';
import WorkflowService from '@/services/WorkflowService';

@Component({
  name: 'ContactForm',
  $_veeValidate: {
    validator: 'new'
  },
  components: {
    FactPhoneNumber
  }
})

export default class ContactForm extends Vue {
  @State private app!: App;
  @State private cms!: CMS;
  @State private proposal!: Proposal;
  @Action('app/setPardotAvailability') private setPardotAvailability!: (value: boolean) => void;
  @Getter('quotation/getFactByFactID') private getFactByFactID!: (factID: string) => any;

  private success: boolean = false;
  private sending: boolean = false;

  private name: string = '';
  private phone: string | null = null;
  private email: string = '';
  private message: string = '';

  public clearFields() {
    this.success = false;
    this.name = '';
    this.phone = '';
    this.email = '';
    this.message = '';
    this.$validator.reset();
    const phoneFact = this.$refs.phoneFact as FactPhoneNumber;
    if (_.isFunction(phoneFact.reset)) {
      phoneFact.reset();
    }
  }

  public populateFields() {
    if (this.proposal.products.length) {
      const contactInfo = this.proposal.contactInfo;
      this.name = `${contactInfo.givenName} ${contactInfo.familyName}`;
      this.phone = contactInfo.phone;
      this.email = contactInfo.email;
    }
  }

   private sendMessage() {
    this.$validator.validate().then((result: boolean) => {
      if (result) {
        this.sending = true;
        this.sendTask().then(() => {
          return;
        })
        .then(() => {
          this.success = true;
          this.sending = false;
          this.setPardotAvailability(true);
        })
        .catch(() => {
          this.sending = false;
          Util.gtmLogCustomEvent(this, 'click', 'tracked_error', {
            error_type: 'technical-issue',
            error_message: 'ContactForm - sendToPardot'
          })
          this.$dialog.open({ type: 'technical-issue', info: 'ContactForm - sendToPardot' });
          this.setPardotAvailability(false);
        });
      }
    });
  }

  private async sendTask() {
    const context = {
      title: 'A user in Coverhub needs assistance',
      type: 'Coverhub request',
      source: window.location.href,
      name: this.name,
      phone: this.phone,
      email: this.email,
      message: this.message,
    };
    return WorkflowService.runWorkflowSync('create-contact-form-task', context, ['task']);
  }


  private mounted() {
    this.populateFields();
  }

  private async sendToCRM() {
    const pardotObj = {
      email: this.email,
      Name: this.name,
      Phone: this.phone,
      Message: this.message
      };
    return _.get(await this.$pardot(), Util.PARDOT_ACTIONS.REPORT_CONTACT_US, Util.PARDOT_ACTIONS.PLACEHOLDER_METHOD)(pardotObj);
  }

  private onPhoneInput(data) {
    this.phone = data.number;
  }

  get country() {
    return this.app.detectedCountry;
  }

  get messageSent() {
    return _.get(this.cms, 'theme.messageSent');
  }
}
