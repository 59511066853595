import en from './en_config';
import fr from './fr_config';

import en_data from './en.json';
import fr_data from './fr.json';

export default { en, fr };

export const fallback = {
  en: en_data,
  fr: fr_data
};
