
























import {Component, Vue} from 'vue-property-decorator';
import { State } from 'vuex-class';
import Util from '@/utils/Util';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import App from '@/models/app';

@Component({
  name: 'ContactInformation'
})

export default class ContactForm extends Vue {
  @State private app!: App;

  get help() {
    const {helpAddress, helpPhone, helpFax, helpEmail} = this.app;
    const helpEmailInString = Util.extractEmailFromHtmlString(helpEmail);
    const helpPhoneInString = () => {
      const doc = new DOMParser().parseFromString(helpPhone, 'text/html');
      const phoneHTML = doc.getElementsByTagName('b');
      const phoneNumber = phoneHTML[0] && phoneHTML[0].innerHTML ? phoneHTML[0].innerHTML : '';
      return phoneNumber ? helpPhone.replace(phoneNumber, `<a href="tel:${phoneNumber}">${phoneNumber}</a>`) : helpPhone;
    };

    return {
      address: {
        html: helpAddress,
        icon: 'mdi-map-marker'
      },
      phone: {
        html: helpPhoneInString(),
        icon: 'mdi-phone',
        handler: () => {
          const doc = new DOMParser().parseFromString(helpPhone, 'text/html');
          const matches = [...doc.querySelectorAll('b')].map((b) => b.textContent);
          const phoneNumber = parsePhoneNumberFromString(matches[0] || '')!.format('RFC3966') || '';
          if (phoneNumber) {
            window.location.href = phoneNumber;
          }
        }
      },
      email: {
        html: `<a href="mailto:${helpEmailInString}">${helpEmailInString}</a>`,
        icon: 'mdi-email',
        handler: () => { window.location.href = `mailto:${Util.extractEmailFromHtmlString(helpEmail)}`; }
      }
    };
  }
}
