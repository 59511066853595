import { render, staticRenderFns } from "./FactTooltip.vue?vue&type=template&id=146da212&"
import script from "./FactTooltip.vue?vue&type=script&lang=ts&"
export * from "./FactTooltip.vue?vue&type=script&lang=ts&"
import style0 from "./FactTooltip.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VIcon,VTooltip})
