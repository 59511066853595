










































































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import { Action } from 'vuex-class';
import countriesSorted from './countries.js';
import FactMixin from './FactMixin.vue';
import FactTooltip from './FactTooltip.vue';
import _ from 'lodash';

import {
    parsePhoneNumber,
    parseIncompletePhoneNumber,
    parsePhoneNumberFromString,
    formatIncompletePhoneNumber,
    getCountryCallingCode,
    CountryCode,
  } from 'libphonenumber-js/max';

@Component({
  name: 'FactPhoneNumber',
  components: {
    FactTooltip
  }
})
export default class FactPhoneNumber extends Mixins(FactMixin) {
  @Prop() private country!: CountryCode;
  @Prop() private countryCodeLabel!: string;
  @Prop() private field?: any;
  @Action('app/setDetectedCountry') private setDetectedCountry: any;

  private selectedCountry = this.country || null;
  private phoneNumber = this.modelValue || null;
  private countryFocus = false;
  private COUNTRIES = countriesSorted;

  public reset() {
    this.updateModelAndValidate('', true);
    this.phoneNumber = '';
  }

  @Watch('modelValue')
  private prefillValue(phoneNumber: string) {
    if (!this.phoneNumber || !this.isFocused) {
      this.phoneNumber = phoneNumber;
      this.parsePhone(phoneNumber);
    }
  }

  @Watch('selectedCountry')
  private onCountryChange(value) {
    if (value !== this.app.detectedCountry) {
      this.setDetectedCountry(value);
    }
  }

  @Watch('country')
  private onPropCountryChanged(value) {
    if (value !== this.selectedCountry) {
      this.selectedCountry = value;
    }
  }

  get getCountries() {
    const selected = _.find(this.COUNTRIES, { code: this.selectedCountry});
    return this.countryFocus ? this.COUNTRIES : selected ? [selected] : [];
  }

  private isRequired() {
    return _.get(this, 'factData.validators', []).includes('required') || _.get(this, 'factData.mandatory');
  }

  private getName() {
    return this.isBasic ? this.factData.label : this.getUID();
  }

  private countryFilter(item, queryText, itemText) {
    const itemName = _.get(item, 'name', '').toLowerCase();
    return this.countryFocus ? (itemName.includes(queryText.toLowerCase()) || itemText.toLowerCase().includes(queryText.toLowerCase())) : itemName.includes(this.selectedCountry.toLowerCase());
  }

  private mounted() {
    this.selectedCountry = this.app.detectedCountry;
    const input = document.querySelector('input[name="country-select"]') as HTMLInputElement;
    if (input) {
      input.onkeydown = (e) => {
        if (e.key === 'Tab') {
          this.$emit('keydown-tab', e);
        }
      };
    }
  }

  get phoneNumberFormatted() {
    try {
      if (!this.phoneNumber) {
        return this.phoneNumber;
      }
      const phoneNumber = parsePhoneNumber(this.phoneNumber, this.selectedCountry);
      return formatIncompletePhoneNumber(`${phoneNumber.number}`, (this.selectedCountry as CountryCode));
    } catch (error) {
      return this.phoneNumber;
    }
  }

  private onInput(value: any) {
    try {
      this.phoneNumber = parseIncompletePhoneNumber(value);
      const countryFromInput = _.get(parsePhoneNumberFromString(this.phoneNumber), 'country', this.selectedCountry);
      if (countryFromInput !== this.selectedCountry) {
        this.selectedCountry = countryFromInput;
        this.$emit('country', this.selectedCountry);
      }
    } catch (e) {
      console.log('Phone: parse failed on country');
    }
    this.parsePhone(value);
  }

  private onCountry(event) {
    this.selectedCountry = _.get(event, 'code', '' as CountryCode);
    if (!this.phoneNumber || this.phoneNumber.length === 0) {
      return;
    }
    if (this.selectedCountry) {
      try {
        const validPhone = parsePhoneNumber(`${this.phoneNumber}`, this.selectedCountry);
        if (validPhone.isValid() && validPhone.country === this.selectedCountry) {
          (this as any).$resetFactErrors('country-select');
        }
      } catch (e) {
        console.log('Phone: parse failed on country');
      }
    }
    this.modelValue = '';
    this.$emit('country', this.selectedCountry);
    this.$nextTick(() => {
      this.$validator.validate();
    });
  }

  private getPrefix(code) {
    try {
      return `(+${getCountryCallingCode(code)})`;
    } catch (e) {
      return '';
    }
  }

  private parsePhone(value) {
    if (!value) {
      return;
    }
    try {
      const phoneNumber = parsePhoneNumber(`${this.phoneNumber}`, this.selectedCountry);
      const phoneNumberDetails = {
        country: phoneNumber.country,
        countryCallingCode: phoneNumber.countryCallingCode,
        number: phoneNumber.number,
        numberFormatted: phoneNumber.formatInternational(),
        nationalNumber: phoneNumber.nationalNumber,
        nationalNumberFormatted: this.phoneNumberFormatted,
        uri: phoneNumber.getURI(),
        possible: phoneNumber.isPossible(),
        valid: phoneNumber.isValid(),
        type: phoneNumber.getType()
      };
      this.modelValue = phoneNumberDetails.numberFormatted;
      if (phoneNumberDetails.country) {
        this.selectedCountry = phoneNumberDetails.country;
      }
      this.$emit('update', phoneNumberDetails);
      this.$emit('valid', phoneNumberDetails.valid);
      if (phoneNumberDetails.valid) {
        (this as any).$resetFactErrors('country-select');
      }
      return phoneNumberDetails;
    } catch (error) {
      this.modelValue = value;
      this.phoneNumber = value;
      this.$emit('update', {
        country: this.selectedCountry,
        countryCallingCode: '',
        number:  value,
        valid: false
      });
      this.$emit('error', error);
    }
  }

  private onBlur() {
    this.updateModelAndValidate(this.modelValue);
  }
}
