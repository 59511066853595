






import { Component, Vue } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import EventBus from '@/common/EventBus';
import AuthService from '@/services/AuthService';
import _ from 'lodash';
import config from '@/config';
import SentryService from '@/services/SentryService';
import {APIResource} from '@/utils/APIResources';

@Component({
  name: 'AppContainer',
})
export default class AppContainer extends Vue {
  @State private app: any;
  @Action('app/init') private initApp: any;
  @Action('app/setValue') private setValue: any;
  @Action('app/resetRenewalResponse') private resetRenewalResponse: any;
  @Action('app/setProposalNumber') private setProposalNumber: any;
  @Action('app/setBundleId') private setBundleId: any;
  @Action('auth/cmsAuthentication') private cmsAuthentication: any;
  @Action('cms/loadMessages') private loadMessages: any;
  @Action('cms/loadProducts') private loadCMSProducts: any;
  @Action('cms/loadTheme') private loadTheme: any;
  @Action('cms/loadCatalogueLayout') private loadCatalogueLayout: any;
  @Action('cms/resetProducts') private resetCMSProducts: any;

  private gettingToken = false;
  private currHeight = 0;
  private currWidth = 0;

  private created() {
    this.currHeight = window.innerHeight;
    this.currWidth = window.innerWidth;
    // window.onresize = () => {
    //   const tempHeight = window.innerHeight;
    //   const tempWidth = window.innerWidth;
    //   const el = document.activeElement as HTMLElement;
    //   if (this.currWidth !== tempWidth) {
    //     if (el) {
    //       el.blur();
    //     }
    //   } else if (_.get(this, Util.MOBILE_BREAKPOINT) && !this.$isIE) {
    //     if (this.currHeight - 120 > tempHeight ) {
    //       this.setValue({code: 'keyboardUp', value: true});
    //       this.$nextTick(() => {
    //         if (el) {
    //           const top = (window.pageYOffset + el.getBoundingClientRect().top);
    //           window.scrollTo({behavior: 'smooth', left: 0, top: top - (this.$vuetify.breakpoint.height / 2)});
    //         }
    //       });
    //     } else {
    //       this.setValue({code: 'keyboardUp', value: false});
    //     }
    //     this.currHeight = tempHeight;
    //   }
    //   this.currWidth = tempWidth;
    //   this.currHeight = tempHeight;
    // };

    EventBus.$on('need-token', (callback) => {
      if (this.gettingToken) {
        return;
      }
      this.gettingToken = true;
      const token = _.get(this.app, 'originUrl', '').replace(/^.*token=/, '').replace(/&.*/, '');
      const ts = sessionStorage.targetSegment + (sessionStorage.subSegment ? '-' + sessionStorage.subSegment : '');
      if (this.app.isContinuation) {
        AuthService.authLogin(token, this.app.targetShiftClientId, 'continuation')
            .then((result: any) => {
              localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
              sessionStorage.setItem('login-token-' + config.environment + '-' + ts, result.data.data.token);
              APIResource.setUser();
              if (callback) {
                callback();
              }
              this.gettingToken = false;
            })
            .catch((e: any) => {
                console.error(e);
              });
      } else if (this.app.isRenewal) {
        AuthService.authLogin(token, this.app.targetShiftClientId, 'renewal')
            .then((result: any) => {
              localStorage.removeItem('guest-token-' + config.environment + '-' + ts);
              sessionStorage.setItem('login-token-' + config.environment + '-' + ts, result.data.data.token);
              APIResource.setUser();
              if (callback) {
                callback();
              }
              this.gettingToken = false;
            })
            .catch((e: any) => {
              console.log(e);
              SentryService.logException({ type: 'SHIFT', error: e });
            });
      } else {
        AuthService.authGuest(this.app.targetShiftClientId)
            .then((result: any) => {
              sessionStorage.removeItem('login-token-' + config.environment + '-' + ts);
              localStorage.setItem('guest-token-' + config.environment + '-' + ts, result.data.data.token);
              APIResource.setUser(true);
              if (callback) {
                callback();
              }
              this.gettingToken = false;
            })
            .catch((e: any) => {
              console.error(e);
            });
      }
    });

    EventBus.$on('error-404', () => {
      this.$router.push({ name: 'error' });
    });

    EventBus.$on('cookies-disabled', () => {
      this.$dialog.open({ type: 'cookies-disabled', info: 'AppContainer: cookies-disabled', level: 'error' });
    });

    EventBus.$on('cleanup-journey', (callback) => {
      setTimeout(() => {
        sessionStorage.removeItem('login-token-' + config.environment + '-' + this.app.targetSegment);
        this.setValue({ code: 'isContinuation', value: false });
        this.setValue({ code: 'isRenewal', value: false });
        this.setValue({ code: 'isSavedProposal', value: false });
        this.setValue({ code: 'dirty', value: false });
        this.setValue({ code: 'originUrl', value: '' });
        this.resetRenewalResponse();
        this.setProposalNumber('');
        this.setBundleId('');
        if (callback) {
          callback();
        }
      }, 0);
    });

    EventBus.$on('init-app', async (callback) => {
      const targetSegment = (this.$route.params.targetSegment || this.app.targetSegment || '').toUpperCase();
      const availableSegments = _.get(config, 'segments', '').toUpperCase().split(',');
      if (!targetSegment || (!availableSegments.includes('*') && !availableSegments.includes(targetSegment))) {
        console.error(`Unknown segment: ${targetSegment}`);
        this.$router.push({ path: '/error' });
        return;
      }
      const { language, profile, line, areaOfService, products, subSegment }: any = this.$route.query;
      const segmentConfig = await this.cmsAuthentication({ targetSegment, language }).catch((e) => {
        console.error(e);
      });
      if (!segmentConfig) {
        console.error(`Could not get target segment ${targetSegment} configuration.`);
        this.$router.push({ path: '/error' });
        return;
      }
      segmentConfig.targetSegment = targetSegment;
      if (subSegment) {
        const subSegmentClientId = segmentConfig.subSegments[subSegment]?.targetShiftClientId;
        if (!subSegmentClientId) {
          console.error('Sub segment client id not found: ' + subSegmentClientId);
          this.$dialog.open({ type: 'technical-issue', info: 'AppContainer - on initApp', level: 'error' });
          return;
        }
        segmentConfig.targetShiftClientId = subSegmentClientId;
        if (segmentConfig.subSegmentContactInfoFacts) {
          segmentConfig.contactInfoFacts = segmentConfig.subSegmentContactInfoFacts;
        }
      } else {
        segmentConfig.targetShiftClientId = this.app.isContinuation ? this.app.targetShiftClientId : segmentConfig.targetShiftClientId;
      }

      let initOk = true;
      Promise.all([
        this.initApp({...segmentConfig, ...{language, profile, line, areaOfService, products}}).catch(() => { initOk = false; }),
        this.loadTheme(targetSegment).catch(() => { initOk = false; }),
        this.loadCatalogueLayout(),
        this.loadMessages().catch(() => { initOk = false; }),
        this.resetCMSProducts().catch(() => { initOk = false; }),
        this.loadCMSProducts().catch(() => { initOk = false; }),
      ]).then((responses: any[]) => {
        if (initOk) {
          this.$global.applyTheme();
        } else {
          this.$dialog.open({ type: 'technical-issue', info: 'AppContainer - on initApp', level: 'error' });
          return;
        }
      });
      if (callback) {
        callback();
      }
    });
  }
}
