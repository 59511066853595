import Vue from 'vue';
const EventBus = new Vue();

window.addEventListener('message', (e: any) => {
  const event = e.data && e.data.event || '';
  if (event.match(/^iframe-/)) {
    EventBus.$emit(e.data.event, e.data.data);
  }
});
export default EventBus;
