




































import EventBus from '@/common/EventBus';
import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import {State} from 'vuex-class';

const CLOSE_TIMEOUT = 500;

@Component({
  name: 'LoadingModal'
})
export default class LoadingModal extends Vue {

  @State private cms: any;
  @State private app: any;

  private counter = 0;
  private counterText: string | null = null;

  private created() {
    EventBus
      .$on('open-loading-dialog', this.openLoadingDialog)
      .$on('close-loading-dialog', this.closeDialog);
  }

  get hasText() {
    return !!this.app.loadingText || !!this.counterText;
  }

  get isVisible() {
    return this.hasText || this.counter > 0;
  }

  private openLoadingDialog(text?: string) {
    if (text) {
      this.counterText = text;
    }
    if (this.counter < 0) {
      this.counter = 0;
      this.counterText = null;
    }
    this.counter++;
  }

  private closeDialog(delay: number = -1) {
    setTimeout((d) => {
      this.counter--;
      if (this.counter <= 0) {
        this.counterText = null;
      }
    }, delay > -1 ? delay : CLOSE_TIMEOUT);
  }
}
