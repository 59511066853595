








import {Component, Vue, Prop} from 'vue-property-decorator';

@Component({
	name: 'DottedSpacer',
})
export default class DottedSpacer extends Vue {
  @Prop() private color!: string;
}
