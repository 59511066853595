









import {Component, Vue, Prop} from 'vue-property-decorator';
import Util from '@/utils/Util';
import config from '@/config';
import {State} from 'vuex-class';

@Component({
  name: 'Livechat8x8'
})
export default class Livechat8x8 extends Vue {
  @State private app: any;
  @Prop({default: false}) private error!: boolean;
  private livechat8x8uuid: string | null = null;

  private created() {
    this.livechat8x8uuid = Util.init8x8Livechat(this.app.config.livechat8x8);
    if (this.livechat8x8uuid) {
      (window as any).__8x8Chat = config.livechat8x8;
      window.addEventListener('resize', this.setPosition);
      this.setPosition();
    }
  }

  private setPosition() {
    setTimeout(() => {
      try {
        const actionBar = document.querySelector('.stepper') as HTMLElement;
        const actionBarHeight = getComputedStyle(actionBar).height;
        const livechat8x8 = this.$refs.livechat8x8 as HTMLElement;
        livechat8x8.style.bottom = `${parseInt(actionBarHeight.substring(0, actionBarHeight.length - 2), 10)}px`;
        setTimeout(() => {
          try {
            const livechatHeight = getComputedStyle(document.querySelector('.livechat8x8>a>img') as HTMLElement).height;
            livechat8x8.style.marginTop = `-${parseInt(livechatHeight.substring(0, livechatHeight.length - 2), 10) + (this.error ? 200 : 40)}px`;
          } catch (e) {
            setTimeout(this.setPosition, 1000);
          }
        }, 1000);
      } catch (e) {
        setTimeout(this.setPosition, 1000);
      }
    }, 1000);
  }
}
