







































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Action, State} from 'vuex-class';
import config from '@/config';
import _ from 'lodash';
import EventBus from '@/common/EventBus';
import Util from '@/utils/Util';
import ContactDialog from '@/components/layouts/ContactDialog/ContactDialog.vue';

@Component({
  name: 'AppBar',
  components: {
    ContactDialog
  }
})
export default class AppBar extends Vue {
  @Action('app/setLanguage') private setLanguage: any;
  @Action('proposal/reset') private proposalReset: any;
  @Action('quotation/reset') private quotationReset: any;
  @Prop() private isShow!: boolean;
  @State private app: any;
  @State private cms: any;
  @State private auth: any;
  private contactFormVisible: boolean = false;
  private showWarning: boolean = false;
  private warningOffset = '0px';
  private visibleOnPages = [
    'landing',
    'download-forms',
    'renewal-request'
  ];

  // computed property used to handle appbar visibiblity
  // only apply to mobile screen;
  get isShowAppBar() {
    // is mobile
    if (this.$vuetify.breakpoint.xsOnly) {
      return this.isShow;
    } else { // alway show app bar when non mobile view
      return true;
    }
  }

  get contactFormEnabled() {
    return _.get(this.app.config, 'contactForm', false);
  }

  get logoDimensions() {
    // render smaller logo if segment logo exist on mobile view
    if (this.$vuetify.breakpoint.xsOnly && this.segLogo) {
      return {
        maxHeight: 35,
        maxWidth: 90
      };
    }

    return {
      maxHeight: _.get(this.cms, 'theme.logoDimensions.maxHeight', 35),
      maxWidth: _.get(this.cms, 'theme.logoDimensions.maxWidth', 90),
    };
  }

  get logo() {
    return _.get(this.cms, 'theme.logo');
  }

  get segLogoDimensions() {
    // render smaller logo if segment logo exist on mobile view
    if (this.$vuetify.breakpoint.xsOnly && this.segLogo) {
      return {
        maxHeight: 35,
        maxWidth: 100
      };
    }

    return {
      maxHeight: _.get(this.cms, 'theme.segmentLogoDimensions.maxHeight', 35),
      maxWidth: _.get(this.cms, 'theme.segmentLogoDimensions.maxWidth', 100),
    };
  }

  get segLogo() {
    return _.get(this.cms, 'theme.segmentLogo');
  }

  get visible() {
    const languages = typeof this.app.languages === 'string' ? this.app.languages.split(',') : this.app.languages;
    if (languages.length < 2) {
      return false;
    }
    const pages = new RegExp('^(' + this.visibleOnPages.join('|') + ')$');
    return !!_.get(this.$route, 'name', '')!.match(pages);
  }

  get secondaryLanguage() {
    const languages = typeof this.app.languages === 'string' ? this.app.languages.split(',') : this.app.languages;
    const other = languages.find((l) => l !== 'en');
    return other;
  }

  public openContactForm() {
    this.contactFormVisible = true;
  }

  private onWarningChange() {
    const target = document.querySelector('#unsupported-browser') as Element;
    this.warningOffset = target ? getComputedStyle(target).height : '';
  }

  private closeWarning() {
    this.warningOffset = '0px';
    setTimeout(() => {
      this.showWarning = false;
    }, 500);
  }

  private changeLanguage(locale) {
    if (config.locales.includes(locale)) {
      this.setLanguage(locale);
      this.$emit('onLanguageChange', locale);
    }
  }

  private mounted() {
    if (this.$isIE) {
      setTimeout(() => {
        this.showWarning = true;
        this.onWarningChange();
      }, 2000);
    }
  }

  private goHome() {
    if (this.app.dirty) {
      this.$dialog.open({
        icon: _.get(this.cms, 'theme.errorIcon'),
        text: this.$t('navigation.warning'),
        buttons: [
          {
            text: this.$t('button.cancel')
          },
          {
            type: 'main',
            text: this.$t('button.home'),
            onClick: this.acknowledgeHome
          }
        ]
      });
    } else {
      this.acknowledgeHome();
    }
  }

  private goRenewCoverage() {
    console.log(config.urls.renewals);
  }

  private acknowledgeHome() {
    EventBus.$emit('cleanup-journey', () => {
      EventBus.$emit('init-app', () => {
        this.proposalReset();
        this.quotationReset();
        Util.goHome(this.$router, this.app);
      });
    });
  }
}
